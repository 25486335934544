<template>
    <div id="app">
        <b-container fluid>
			<router-view></router-view>
        </b-container>
    </div>
</template>

<script>
    export default {
        name: "App"
    };
</script>