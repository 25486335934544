<template>
    <div>
        contact
    </div>
</template>

<script>

export default {
	name: "Contact"
};

</script>